<template>
    <div>
        <v-btn icon @click="editItem(item)">
            <v-icon small>edit</v-icon>
        </v-btn>
        <v-btn icon @click="deleteCategory(item)" color="danger">
            <v-icon small>delete</v-icon>
        </v-btn>
        <v-dialog v-model="subcategoryModal" persistent width="400px">
            <v-card style="background: var(--v-greyRaised-base) !important;">
                <v-card-title>
                    Add Subcategory <v-spacer></v-spacer>
                    <v-btn icon color="secondary" :loading="savingSubcategory"
                        @click="saveSubcategory()"><v-icon>save</v-icon></v-btn>
                    <v-btn icon color="redPop" @click="subcategoryModal = false"><v-icon>close</v-icon></v-btn>
                </v-card-title>
                <v-card-text>
                    <v-text-field label="Name" v-model="subcategoryItem.name" outlined dense clearable></v-text-field>
                    <v-textarea label="Description" v-model="subcategoryItem.description" clearable outlined
                        rows="6"></v-textarea>
                    <v-row justify="center">
                    </v-row>
                </v-card-text>
            </v-card>
        </v-dialog>

        <v-dialog v-model="editModal" persistent width="600px">
            <v-card style="background: var(--v-greyRaised-base) !important;">
                <v-card-title>
                    Manage Category
                    <v-spacer></v-spacer>
                    <v-btn id="category-save" icon color="secondary" :loading="savingCategory"
                        @click="saveCategory"><v-icon>save</v-icon></v-btn>
                    <v-btn icon color="redPop" @click="editModal = false"><v-icon>close</v-icon></v-btn>
                </v-card-title>
                <v-card-text>
                    <v-row>
                        <v-col cols="12" sm="6">
                            <v-text-field id="category-name" label="Name" v-model="item.name" outlined dense
                                clearable></v-text-field>
                            <v-textarea label="Description" v-model="item.description" clearable outlined dense
                                rows="6"></v-textarea>
                        </v-col>
                        <v-col cols="12" sm="6">
                            <v-list dense subheader>
                                <v-subheader
                                    style="font-size: 20px;display: flex;flex-direction: row;justify-content: center;">Subcategories
                                    <v-btn color="primary" @click="addSubCategory()"
                                        icon><v-icon>add_circle_outline</v-icon></v-btn></v-subheader>
                                <v-list-item v-for="(subcategory, index) in item.serviceFailureSubcategories"
                                    :key="subcategory.id">
                                    <v-list-item-content>
                                        <v-list-item-title>
                                            {{ subcategory.name }}
                                            <v-btn icon @click="editSubCategory(subcategory, index)">
                                                <v-icon small>edit</v-icon>

                                            </v-btn>
                                            <v-btn @click="deleteSubcategory(subcategory)" color="danger" icon>
                                                <v-icon small>delete</v-icon>
                                            </v-btn>
                                        </v-list-item-title>
                                        <v-list-item-subtitle style="font-size: 12px" v-if="subcategory.description"
                                            class="text-wrap">
                                            {{ subcategory.description }}
                                        </v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>
                                <v-list-item v-if="item.serviceFailureSubcategories.length == 0">
                                    <v-list-item-content class="text-center">
                                        <span style="color: grey">No subcategories</span>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-list>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
export default {
    data: () => ({
        addressModal: false,
        item: {},
        addressItem: {},
        subcategoryItem: {},
        editModal: false,
        subcategoryModal: false,
    }),
    mounted() {
        this.item = this.params.data;
    },
    methods: {
        editSubCategory(item, index) {
            this.subcategoryItem = item
            this.subcategoryItem.index = index
            this.subcategoryModal = true
        },
        deleteCategory(category) {
            this.$confirm('Are you sure you want to delete this category?', 'Confirm', {
                center: true,
                confirmButtonText: 'Yes',
                cancelButtonText: 'No',
                type: 'plain'
            }).then(async () => {
                this.deletingCategory = true
                if (category.id) {
                    await this.$API.updateServiceFailureCategory({
                        id: category.id,
                        isActive: false,
                        isDeleted: true
                    })
                    this.item.serviceFailureSubcategories.push(result)
                }
                let findIndex = this.categories.findIndex(x => x.id == category.id)
                this.categories.splice(findIndex, 1)
                this.$message({
                    type: 'success',
                    message: 'Successfully deleted!'
                });
                this.deletingCategory = false
            }).catch(() => {
                this.deletingCategory = false
                this.$message({
                    type: 'info',
                    message: 'Delete canceled'
                });
            });
        },
        deleteSubcategory(subcategory) {
            this.$confirm('Are you sure you want to delete this subcategory?', 'Confirm', {
                center: true,
                confirmButtonText: 'Yes',
                cancelButtonText: 'No',
                type: 'plain'
            }).then(async () => {
                this.deletingSubcategory = true
                if (subcategory.id) {
                    await this.$API.updateServiceFailureSubcategory({
                        id: subcategory.id,
                        isActive: false,
                        isDeleted: true
                    })
                }
                // let findIndex = this.item.serviceFailureSubcategories[subcategory.index]
                // if(findIndex){
                console.log("SPlicing index", subcategory.index)
                this.item.serviceFailureSubcategories.splice(subcategory.index, 1)
                // }
                this.$message({
                    type: 'success',
                    message: 'Successfully deleted!'
                });
                this.subcategoryModal = false
                this.subcategoryItem = {}
                this.deletingSubcategory = false

            }).catch(() => {
                this.deletingSubcategory = false
                this.$message({
                    type: 'info',
                    message: 'Delete canceled'
                });
            });
        },
        addSubCategory() {
            this.subcategoryItem = {
                serviceFailureCategoryId: this.item.id ?? null,
                index: this.item.serviceFailureSubcategories.length
            }
            this.subcategoryModal = true
        },
        editItem(item) {
            this.editModal = true
            this.item = item
        },
        closeAddressModal() {
            this.addressModal = false;
            this.addressItem = {};
        },
        async saveSubcategory() {
            this.savingSubcategory = true
            // if (this.subcategoryItem.id) {
            //     await this.$API.updateServiceFailureSubcategory(this.subcategoryItem)
            //     this.$message.success('Successfully updated subcategory!')
            //     let findIndex = this.item.serviceFailureSubcategories.findIndex(x => x.id == this.subcategoryItem.id)
            //     this.item.serviceFailureSubcategories[findIndex] = this.subcategoryItem
            // } 


            let result = await this.$API.createServiceFailureSubcategory(this.subcategoryItem)
            this.item.serviceFailureSubcategories.push(result)
            this.$message.success('Successfully created subcategory!')

            // else {
            //     let findIndex = this.item.serviceFailureSubcategories.findIndex(x => x.index == this.subcategoryItem.index)
            //     if (findIndex > -1) {
            //         this.item.serviceFailureSubcategories[findIndex] = this.subcategoryItem
            //     } else {
            //         this.item.serviceFailureSubcategories.push(this.subcategoryItem)
            //     }
            // }
            this.subcategoryModal = false
            this.subcategoryItem = {}
            this.savingSubcategory = false
        },

    },
};
</script>